// PopupCarousel.tsx
import { useCallback, useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import image1_png from '../assets/popupModal/Image1.png';
import image1_webp from '../assets/popupModal/Image1.webp';
import image2_png from '../assets/popupModal/Image2.png';
import image2_webp from '../assets/popupModal/Image2.webp';
import image3_png from '../assets/popupModal/Image3.png';
import image3_webp from '../assets/popupModal/Image3.webp';
import image4_png from '../assets/popupModal/Image4.png';
import image4_webp from '../assets/popupModal/Image4.webp';
import '../styles/PopupCarousel.css';

export const PopupCarousel = () => {
  const items = [
    {
      id: 1,
      title: 'Image 1',
      image: image1_png,
      webp: image1_webp,
    },
    {
      id: 2,
      title: 'Image 2',
      image: image2_png,
      webp: image2_webp,
    },
    {
      id: 3,
      title: 'Image 3',
      image: image3_png,
      webp: image3_webp,
    },
    {
      id: 4,
      title: 'Image 4',
      image: image4_png,
      webp: image4_webp,
    },

    // Add more items as needed
  ];
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const handleChangeSlide = useCallback(
    (slideIndex: number) => {
      swiperRef?.slideTo(slideIndex);
    },
    [swiperRef]
  );

  return (
    <div id="popupSlideSwiper">
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        centeredSlides={true} // Center slides
        id="slideSwiper"
        style={{ overflow: 'hidden' }} // Full width and hide overflow
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        onSlideChange={(slide) => {
          setCurrentSlide(slide.activeIndex);
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <ClickableCard
              title={item.title}
              image={item.image}
              webp={item.webp}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="alignItemsCenter" id="slidePagination">
        {items.map((item, index) => {
          return (
            <div
              key={`${index}-circle`}
              className="pageCircle"
              style={{
                backgroundColor: `${currentSlide == index ? 'var(--tertiary-shade-2)' : 'var(--tertiary)'}`,
              }}
              onClick={() => {
                handleChangeSlide(index);
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

const ClickableCard = ({
  title,
  image,
  webp,
}: {
  title: string;
  image: string;
  webp: string;
}) => {
  const handleClick = () => {
    // Add your click handling logic here (e.g., navigate to a page)
    //console.log("Card clicked: " + title);
  };

  return (
    <div className="slide" onClick={handleClick}>
      <img
        src={webp}
        alt={title}
        className="slideImage"
        onError={(e) => {
          e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
          e.currentTarget.src = image; // Fallback to the original JPG or PNG
        }}
      />
    </div>
  );
};
