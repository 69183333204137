import { Link, useLocation, useNavigate } from '@remix-run/react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { RefObject, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { auth } from '~/utils/Auth/firebase-service';
import { getProfile } from '~/utils/ServerRequest';
import logo from '../assets/CumulusLogo_Black.png';
import '../styles/header.css';
import { PopupModal } from './PopupModal';

export const Header = ({
  pricingRef,
}: {
  pricingRef?: RefObject<HTMLDivElement>;
}) => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState<number>();
  const location = useLocation();
  const [profile, setProfile] = useState<Profile | null>(null);

  interface Profile {
    message: string;
    allowance: number;
    projects: [];
    signupComplete: boolean;
    subscription: string;
  }

  useEffect(() => {
    // Ensures user is authorised before initialising variables.
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setLogin(false);
        setSignUp(false);

        const prof = await getProfile();
        setProfile(prof);

        // checks if user has completed information form
        if (!prof.postSignupFormCompleted) navigate('/informationForm');

        setAllowance(prof.allowance);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSignUp = () => {
    setSignUp(!signUp);
  };

  const handleLogin = () => {
    if (document.body.style.overflow !== 'hidden') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }

    setLogin(!login);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => setUser(null))
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  let navigate = useNavigate();

  return (
    <header
      className={location.pathname === '/home' ? 'header' : 'uploads-header'}
    >
      <div id="headerContent">
        <div className="logoHeader">
          <Link to="/home">
            <img
              src={logo}
              width={70}
              id="popupLogo"
              alt="BLUESKEYE AI Logo"
              onError={(e) => {
                e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              }}
            />
          </Link>
        </div>

        {/* Display header content for both home and uploads/projects */}
        {!loading && (location.pathname === '/home' || location.pathname === '/uploads/projects') && (
          <div className="rightButtons">
            {/* Show pricing button only on home page */}
            {location.pathname === '/home' && (
              <button
                className="headerButton"
                onClick={() => {
                  pricingRef?.current?.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Pricing
              </button>
            )}

            {/* Show these buttons when user is not logged in */}
            {user === null ? (
              <>
                <button className="headerButton" onClick={handleLogin}>
                  Log In
                </button>

                {/* Sign Up button - shown on both pages when not logged in */}
                <button id="signupButton" className="headerButton" onClick={toggleSignUp}>
                  Sign Up <FaArrowRight />
                </button>
              </>
            ) : (
              <>
                {/* Show these when user is logged in */}

                {/* Show tokens and subscription info on uploads page */}
                {location.pathname === '/uploads/projects' && (
                  <div className="alignItemsCenter">
                    <button className="clearButton headerButtonText" onClick={() => { }}>
                      {allowance} tokens
                    </button>
                    <p className='headerButton'>signed in as: </p>
                    <span style={{ display: 'flex', alignItems: 'left' }}>
                      <button className="clearButton" onClick={() => { }}>
                        {user.displayName}
                      </button>
                      <div className={`headerButtonText tier-${profile?.subscription.toLowerCase()}`}>
                        {profile?.subscription}
                      </div>
                    </span>
                  </div>
                )}

                {/* My Projects button - only on home page */}
                {location.pathname === '/home' && (
                  <button
                    className="headerButton"
                    onClick={() => navigate('/uploads')}
                  >
                    My Projects
                  </button>
                )}

                {/* Logout button on both pages */}
                <button
                  className="headerButton logoutButton"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </>
            )}
          </div>
        )}

        {signUp && (
          <PopupModal
            mode={'signUp'}
            toggle={toggleSignUp}
            switchModal={() => {
              toggleSignUp();
              handleLogin();
            }}
          />
        )}
        {login && (
          <PopupModal
            mode={'login'}
            toggle={handleLogin}
            switchModal={() => {
              toggleSignUp();
              handleLogin();
            }}
          />
        )}
      </div>
    </header>
  );
};